<template>
  <div>
    <section>
      <h5 class="contact-title">{{ $t("ListingProcess.ContactPerson") }}</h5>
      <span class="secondary-text">
        {{ $t("ListingProcess.ContactPersonDesc") }}
      </span>
      <div class="avatar-container" v-if="!editContact">
        <div class="avatar-header">
          <b-avatar class="avatar"> {{ firstName[0] }}{{ lastName[0] }}</b-avatar>
        </div>
        <div class="avatar-header">
          <p class="username">{{ firstName }} {{ lastName }}</p>
          <p class="email">{{ email }}</p>
          <p class="mobile">{{ mobile }}</p>
        </div>
        <b-col>
          <b-button
            variant="transparent"
            class="border-0 text-primary p-0 btn-edit"
            @click="editContact = true"
          >
            <b-icon icon="pencil-square"></b-icon>
            {{ $t("global.button.edit") }}
          </b-button>
        </b-col>
      </div>

      <b-card class="edit-form" v-else-if="editContact">
        <b-card-body>
          <label>{{ $t("Reservation.FirstName") }}</label>
          <b-form-input
            type="text"
            v-model="firstName"
            @input="checkInput('vehicleContactFirstName', firstName)"
            class="input-pills"
          />
        </b-card-body>

        <b-card-body>
          <label>{{ $t("Reservation.LastName") }}</label>
          <b-form-input
            type="text"
            v-model="lastName"
            @input="checkInput('vehicleContactLastName', lastName)"
            class="input-pills"
          />
        </b-card-body>

        <b-card-body>
          <label>{{ $t("accountSettings.label.mobileNum") }}</label>
          <b-input-group class="row m-0">
            <VuePhoneNumberInput
              class="col p-0"
              v-model="mobile"
              :default-country-code="
                $store.getters.country.Code ? $store.getters.country.Code : 'CA'
              "
              @input="
                checkInput('vehicleContactMobile', mobile),
                  checkInput(
                    'vehicleContactMobileCountryCode',
                    mobilePayload.countryCallingCode
                  )
              "
              @update="setMobilePayload($event)"
              :translations="{
                countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                countrySelectorError: $t('Contact.countrySelectorError'),
                phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                example: $t('Contact.example'),
              }"
            />
          </b-input-group>
        </b-card-body>

        <b-card-body>
          <label>{{ $t("Reservation.Email") }}</label>
          <b-form-input
            type="text"
            v-model="email"
            @input="checkInput('vehicleContactEmail', email)"
            class="input-pills"
          />
        </b-card-body>

        <b-card-body class="mb-0">
          <b-row>
            <b-col>
              <b-btn
                pill
                variant="outline-primary"
                class="w-100"
                @click="(editContact = false), autoFillUserInfo()"
              >
                {{ $t("global.button.edit") }}
              </b-btn>
            </b-col>
            <b-col>
              <b-btn
                pill
                variant="primary"
                class="w-100"
                :disabled="!eligibleContact"
                @click="(editContact = false), saveContact(), sendLocation()"
              >
                {{ $t("Reservation.Submit") }}
              </b-btn>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </section>

    <section>
      <h5 class="contact-title">{{ $t("ListingProcess.VehicleLocationAddress") }}</h5>
      <span class="secondary-text">{{ $t("ListingProcess.VehicleLocationAddressDesc") }}</span>
      <b-row class="avatar-container" v-if="!editLocation">
        <b-col cols="auto">
          <b-avatar class="avatar" variant="whiteBlue">
            <b-icon-geo-alt-fill variant="primary" scale="0.8"/>
          </b-avatar>
        </b-col>
        <b-col cols="auto">
          <p class="mb-1">
            <b class="pl-3" v-show="address2">{{ address2 }} - </b>
            <b>{{ address1 }}</b>
          </p>
          <p class="mb-1">
            {{ city }}<span v-show="zipcode">, {{ zipcode }}</span>
          </p>
          <p class="mb-1">
            <span v-show="province">{{ province }}, </span>{{ userCountryCode.Name }}
          </p>
        </b-col>
        <b-col>
          <b-button
            variant="transparent"
            class="border-0 text-primary p-0"
            @click="editLocation = true"
          >
            <b-icon icon="pencil-square"/>
            {{ $t("global.button.edit") }}
          </b-button>
        </b-col>
      </b-row>

      <div class="card edit-form" v-else-if="editLocation">
        <TheAddressForm
          :countryLocked="userCountryCode"
          @addressChange="handleAddress"
          :key="componentKey"
        />

        <b-row>
          <b-col>
            <b-btn
              variant="outline-primary"
              class="w-100 submit-address"
              @click="(editLocation = false), forceRerender()"
              >{{ $t("global.button.cancel") }}</b-btn
            >
          </b-col>
          <b-col>
            <b-btn
              pill
              variant="primary"
              class="w-100 submit-address"
              :disabled="!eligibleLocation"
              @click="(editLocation = false), saveLocation(), sendLocation()"
              >{{ $t("Reservation.Submit") }}</b-btn
            >
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import axios from "axios";
import parsePhoneNumber from "libphonenumber-js";

export default {
  components: {
    VuePhoneNumberInput,
    TheAddressForm: () => import("@/components/global/TheAddressForm.vue"),
  },
  props: ['nextButton'],
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      mobile: null,
      mobileCountryCode: null,
      mobilePayload: null,
      fullAddress: [],
      address1: null,
      address2: null,
      city: null,
      province: null,
      zipcode: null,
      userCountryCode: "CA",
      componentKey: 0,
      editContact: false,
      editLocation: false,
    };
  },
  computed: {
    eligibleContact() {
      if (this.firstName && this.lastName && this.email && this.mobile) return true;
      else return false;
    },
    eligibleLocation() {
      const address = this.fullAddress;
      if (address.streetAddress && address.city && address.region) return true;
      else return false;
    },
  },
  methods: {
    handleAddress(address) {
      this.fullAddress = address;
    },
    checkInput(store, model) {
      this.$store.dispatch(store, model);
    },
    setMobilePayload(event) {
      if(!event.phoneNumber) return false
      const mobileNumber = parsePhoneNumber(event.phoneNumber.toString(), event.countryCode);
      if(mobileNumber) {
        const payload = {
          ...mobileNumber,
          isValid: mobileNumber ? mobileNumber.isValid() : false,
          countryCallingCode: mobileNumber.countryCallingCode,
          formatInternational: mobileNumber.formatInternational(),
          formatNational: mobileNumber.number,
          formattedNumber: mobileNumber.number,
          uri: mobileNumber.getURI(),
          e164: mobileNumber.ext
        }
        this.mobilePayload = payload;
      }
    },

    saveContact() {
      this.$store.dispatch("vehicleContactFirstName", this.firstName);
      this.$store.dispatch("vehicleContactLastName", this.lastName);
      this.$store.dispatch("vehicleContactMobile", this.mobile);
      this.$store.dispatch("vehicleContactMobileCountryCode", this.mobileCountryCode);
      this.$store.dispatch("vehicleContactEmail", this.email);
    },
    saveLocation() {
      const address = this.fullAddress;
      this.$store.dispatch("vehicleLocation", this.fullAddress);
      this.address1 = address.streetAddress;
      this.address2 = address.apt;
      this.city = address.city;
      this.province = address.region;
      this.zipcode = address.postal;
    },

    async sendLocation() {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.$store.getters.listingStockID,
          Contact: {
            FirstName: this.firstName,
            LastName: this.lastName,
            Email: this.email,
            Mobile: this.mobile,
            CountryCode: this.userCountryCode.Code,
            Province: this.province,
            City: this.city,
            AddressLine: this.address1,
            AddressLine2: this.address2,
            Zipcode: this.zipcode,
          },
        });
      } catch (error) {
        console.log(error.response.data);
      }
    },
    autoFillUserInfo(contact) {
      const user = contact ? contact : this.$store.getters.user.Contact;
      if (user) {
        this.firstName = user.FirstName;
        this.lastName = user.LastName;
        this.email = user.Email;
        this.mobile = user.Mobile;
        this.userCountryCode = contact ? {
          Code: "CA",
          Name: user.CountryName
        } : user.Country;
      }
      this.saveContact();
    },
    autoFilllocationInfo() {
      const address = this.$store.getters.vehicleLocation,
      user = this.$store.getters.user.Contact;
      if (address) {
        this.address1 = address.streetAddress;
        this.address2 = address.apt;
        this.city = address.city;
        this.province = address.region;
        this.zipcode = address.postal;
      } else {
        this.address1 = user.AddressLine;
        this.address2 = user.AddressLine2;
        this.city = user.City;
        this.province = user.Province;
        this.zipcode = user.ZipCode;
        this.$store.dispatch("vehicleLocation", {
          streetAddress: this.address1,
          apt: this.address2,
          city: this.city,
          region: this.province,
          postal: this.zipcode,
          country: this.userCountryCode.Code,
        });
      }
      if (!this.address1) {
        this.editLocation = true;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
  beforeMount() {
    this.autoFillUserInfo();
    this.autoFilllocationInfo();
  },
};
</script>

<style lang="scss" scoped>
.contact-title {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 2px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.secondary-text {
  color: #4b4b4b;
}
.avatar-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 80px;
}
.avatar {
  width: 97px;
  height: 97px;
  background: #cce1f3;
  margin-right: 16px;
  font-size: 28px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  text-transform: uppercase;
  color: #006ac3;
}
.username {
  color: #111111;
  font-size: 18px;
  margin-bottom: 2px;
  font-weight: 500;
  text-transform: capitalize;
}
.email {
  color: #006ac3;
  margin-bottom: 8px;
  font-size: 18px;
  text-transform: lowercase;
}
.mobile {
  font-size: 18px;
  color: #111111;
}
.btn-edit {
  font-size: 16px;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}
.submit-address {
  height: 52px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter" !important;
  border-radius: 50px;
  &:hover {
    background-color: #00559d !important;
    border-color: #004e90 !important;
    color: white !important;
  }
}
</style>

<style lang="scss">
.edit-form {
  margin-top: 40px;
  width: 455px;
  border-color: #dbdbdb;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  border-radius: 10px;
  .card-body {
    padding: 0;
    margin-bottom: 24px;
  }
  input {
    height: 48px;
  }
}
</style>
